/*! themes/_lemonade.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Lemonade theme variables and styles (Lemonade theme is used in "Landing kit v4")
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Theme color variables
1. Theme gradient
2. Box shadows
=============================================================================
***/

/* ==========================================================================
0. Theme color variables
========================================================================== */
$primary: #039BE5;
$secondary: #0084c4;
$accent: #00D1B2;

//$navbar-item-img-max-height: 3.75rem;

/* ==========================================================================
1. Theme gradient
========================================================================== */
$webkit-hero-gradient: linear-gradient(to right, $secondary, $accent); /* Chrome 10-25, Safari 5.1-6 */
$hero-gradient: linear-gradient(to top, $secondary, $accent); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

/* ==========================================================================
2. Box shadows
========================================================================== */
//Base shadow
$base-shadow: rgba(0, 0, 0, 0.12);

//Primary box shadow
$primary-shadow-from: rgba(3, 155, 229, 0.42);
$primary-shadow-to: rgba(3, 155, 229, 0.2);
$primary-box-shadow:  0 14px 26px -12px $primary-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $primary-shadow-to !important;

//Secondary box shadow
$secondary-shadow-from: rgba(0, 132, 196, 0.42);
$secondary-shadow-to: rgba(0, 132, 196, 0.2);
$secondary-box-shadow:  0 14px 26px -12px $secondary-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $secondary-shadow-to !important;

//Accent box shadow
$accent-shadow-from: rgba(0, 209, 178, 0.42);
$accent-shadow-to: rgba(0, 209, 178, 0.2);
$accent-box-shadow:  0 14px 26px -12px $accent-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $accent-shadow-to !important;
