/*! _landing-v3.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Landing kit v4 SCSS file
Changes specific to BrewEngage website
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Hero styles
0.1 0. Wallop fullscreen slider (RK)
0.2 0. Chat Icon box
1. Landing page 
2. Pricing page
3. Help center
4. Help category
5. Help article 
6. Media queries
=============================================================================
***/
body, input, button, select, textarea {
    font-family: "Inter", -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

img.partner-logo {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    opacity: 0.45;
    filter: alpha(opacity=45); /* For IE8 and earlier */
    
    height: 70px !important;
}

.navbar-wrapper {
    .navbar-brand {
        img {
            height: 46px !important;
        }
    }

    &.navbar-faded {
        .navbar-brand {
            img {
                height: 34px !important;
            }
        }
    }
}

/* ==========================================================================
0. Hero styles
========================================================================== */
// .hero-foot.is-pulled {
//     margin-top: -65px;
// }

.hero {
    position: relative;
    max-height: 90vh;
    background-color: #f5f5f5;
    // background-image: url("/assets/images/illustrations/mockups/landing3/attentive-audience-community-34291.jpg");
    .is-hero-caption {
        position: relative;
        z-index: 1;

        .title {
            margin-bottom: 10px !important;
        }

        .subtitle {
            max-width: 450px;
            margin: 0 auto;
        }

        .button-wrap {
            margin-top: 16px;
        }
    }

    .hero-shape {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        min-height: 500px;
        object-fit: cover;
        z-index: 0;

        &.is-1v {
            min-height: 595px;
        }

        &.is-2v {
            min-height: 600px;
        }

        &.is-3v {
            min-height: 595px;
        }

        &.is-4v {
            min-height: 550px;
        }
    }
}

.hero-body {
    padding: 0px;
    padding-top: 0px;
    //page title style
    .title {
        &.main-title {
            color: $white;
            z-index: 999;
            font-size: 5.25rem;
        }
        &.big-title {
            color: $blue-grey;
            z-index: 999;
            font-size: 5.4rem;
        }
        &.page-title {
            font-size: 5.2rem;
        }
        &.medium-title {
            font-size: 3.7rem;
        }
        &.small-title {
            font-size: 2.9rem;
        }
    }
    //Subtitle styles
    .subtitle {
        &.page-subtitle {
            font-size: 2.3rem;
            padding: 2% 10% 2% 10%; //RK
        }
        //RK: BEGIN
        &.page-subtitle-small {
            font-size: 2rem;
            font-weight: 600;
            padding: 2% 10% 2% 10%;
            color: #6a6a6b !important;
            // color: #f0da19 !important;
        }
        //RK: END
    }

    // //Small clients logo under hero caption
    // .clients-small {
    //     display: flex;
    //     justify-content: flex-start;
    //     align-content: center;
    //     align-items: center;
    //     padding: 15px 0;
    //     img {
    //         height: 42px;
    //         margin: 0 5px;
    //     }
    //     &.centered {
    //         justify-content: center !important;
    //     }
    // }
    // //Helpers
    // &.is-relative {
    //     position: relative;
    // }
}

/* ==========================================================================
0. Wallop fullscreen slider
========================================================================== */
.Wallop {
    top: -65px;
    .Wallop-list {
        width: 100%;
        height: 80vh; //RK: changed from 60vh
    }
    .Wallop-item {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .Wallop-buttonPrevious, .Wallop-buttonNext {
        position: absolute;
        height: 100%;
        width: 20%; //rk
        top: 14%; // 48%;
        background: none;
        border: none;
        cursor: pointer;
        z-index: 101;
        &:hover {
            i {
                opacity: 0.8;
                transform: scale(1.05);
            }
        }
        i {
            font-size: 1px; // 3.5rem;
            color: $white;
            transition: all .4s;
        }
        &:active, &:focus {
            outline: none !important,
        }
    }
    .Wallop-buttonPrevious {
        left: 2px; //20px;
    }
    .Wallop-buttonNext {
        right: 2px; //20px;
    }
    .Wallop-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        // background: rgba(0,0,0,0.3);
        z-index: 1;
    }

    /* Pagination */
    .Wallop-pagination {
        text-align: center;
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        bottom: 40px;
        z-index: 100;
    }

    .Wallop-dot {
        text-indent: -9999px;
        border: 0;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        padding: 0;
        margin: 5px;
        background-color: #ccc;
        cursor: pointer;
        -webkit-appearance: none;  
        &:active, &:focus {
            outline:  none !important;
        }
    }

    .Wallop-dot--current {
        background-color: $primary;
    }
    //Slider caption
    .Wallop-caption-wrapper {
        align-items: center;
        min-height: 100vh;
        position: relative;
        z-index: 99;
        .container {
            position: relative;
            min-height: 500px;
            height: 100vh;
            display: flex;
            .caption-inner {
                /*position: absolute;
                top: calc(50% - 135px);
                left: 20px;
                max-width: 40%;*/
                h1 {
                    font-weight: 700;
                    font-size: 3.8rem;
                    line-height: 3.8rem;
                    color: $white;
                }
                .caption-divider {
                    width: 30%;
                    height: 3px;
                    background: $white;
                    margin: 10px 0;
                }
                .caption-text {
                    //max-width: 90%;
                    //padding: 10px 0;
                    color: $white;
                    font-size: 1.05rem;
                    font-weight: 400;
                    .action {
                        padding: 20px 0;
                    }
                }
            }
        }
    }
}

//Wallop slider media query (mobile)
@media (max-width: 768px) {
    .caption-inner {
        max-width: 100% !important;
        top: 30% !important;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        .caption-divider {
            height: 3px !important;
            margin: 10px auto !important;
        }
        h1 {
            font-size: 3.2rem !important;
        }
        .caption-text span {
            margin: 0 20px;
            display: block;
        }
    }
}

//Wallop slider media query (tablet in portrait mode)
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    .caption-inner {
        text-align: center;
        position: relative;
        top: 40vh !important;
        .caption-divider {
            margin: 10px auto !important;
            height: 3px !important;
        }
        .caption-text {
            padding: 0 15%;
        }
    }
    .is-tablet-padded {
        padding: 0 20% !important;
    }
}

@media only screen and (min-width: 769px), print {
    .hero.is-medium .hero-body {
        padding-top:0px;
    }
}

/* ==========================================================================
0.2.0 Icon box
========================================================================== */

.chat-icon-box {
    i {
        font-size: 3.8rem;
        font-weight: normal;
        color: $primary;
    }
    .box-title {
        // font-family: 'Nexa Light', sans-serif;
        text-transform: uppercase;
        padding: 5px 0;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        color: $primary;
    }
    .box-text {
        font-size: 1.1rem;
    }
}

.square-icon-box {
    i {
        font-size: 3.8rem;
        font-weight: normal;
        color: $primary;
    }
    .box-title {
        // font-family: 'Nexa Light';
        text-transform: uppercase;
        font-size: 1.1rem;
        font-weight: 700;
    }
    .box-text {
        font-size: 1.1rem;
    }
}

/* ==========================================================================
1. Landing page styles
========================================================================== */

/* ==========================================================================
2. Pricing page
========================================================================== */

/* ==========================================================================
3. Help center
========================================================================== */


/* ==========================================================================
5. Help category
========================================================================== */


/* ==========================================================================
5. Help article
========================================================================== */


/* ==========================================================================
6. Media queries
========================================================================== */
@media (max-width: 768px) {
    .title.big-title {
        font-size: 3.1rem !important;
    }
}


.product-chip {
    text-transform: capitalize;
    background-color: #ffe01b;
    padding: 10px;
    border-radius: 11px;
    font-size: 1.6rem;
    font-weight: 700;
}

.special-divider {
    position: relative;
    right: 0;
    left: 0;
    top: -30px;
    z-index: 2;
    max-width: 64px;
    margin: 0 auto;
}

.section {
    padding-bottom: 0rem !important;
    .section-title-wrapper {
        // padding-top: 60px !important;
    }
}

.blurp-text {
    .quoted-text {
        color: $basaltic-grey;
        font-size: 1.3rem;
    }

    .fa-quote-left {
        color: #7c4dff;
        margin: 20px 0 !important;
    }
    .source-name {
        margin-top: 20px;
        font-weight: 400;
        font-size: 0.8rem;
        color: #7c4dff;
    }
    .source-position {
        font-size: 0.7rem;
        color: $blue-grey;
    }
    //colors
    &.primary {
        .source-name {
            color: $primary;
        }
        .fa-quote-left {
            color: $primary;
        }
    }
    &.secondary {
        .source-name {
            color: $secondary;
        }
        .fa-quote-left {
            color: $secondary;
        }
    }
    &.accent {
        .source-name {
            color: $accent;
        }
        .fa-quote-left {
            color: $accent;
        }
    }

}