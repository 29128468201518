/*! _footer.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Footer styles
Changes specific to BrewEngage website
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Dark footer
1. Light Footer
2. Light left Footer
3. Dark left Footer
4. Light medium footer
=============================================================================
***/

/* ==========================================================================
0. Dark footer
========================================================================== */
footer.footer-dark {
    .special {
        padding-left: 5px;
        padding-right: 5px;
        background-color: #f5f5f56b;
    }

    .border-top {
        border-top: 1px solid #e3e3e3;
    }

    .footer-column {
        .column-item-2-line {
            padding-bottom: 10px;
            a {
                color: $title-grey;
                &:hover {
                    color: $primary;
                }
            }
            .description {
                color: $title-grey;
                font-size: 0.75rem;
            }
        }
    }
    .small-footer-logo {
        height: 36px;
    }

    .links {
        display: flex;
        justify-content: center;
        align-items: center;
        a {
            color: $title-grey;
            margin: 0 5px;
            &:hover {
                color: $primary;
            }
        }
    }
}

/* ==========================================================================
1. Light Footer
========================================================================== */
footer.footer-light {
    .special {
        padding-left: 5px;
        padding-right: 5px;
        background-color: #f5f5f56b;
    }

    .border-top {
        border-top: 1px solid #e3e3e3;
    }

    .footer-column {
        .column-item-2-line {
            padding-bottom: 10px;
            a {
                color: $title-grey;
                &:hover {
                    color: $primary;
                }
            }
            .description {
                color: $title-grey;
                font-size: 0.75rem;
            }
        }
    }
    .small-footer-logo {
        height: 36px;
    }

    .links {
        display: flex;
        justify-content: center;
        align-items: center;
        a {
            color: $title-grey;
            margin: 0 5px;
            &:hover {
                color: $primary;
            }
        }
    }
}

/* ==========================================================================
2. Light left Footer
========================================================================== */



/* ==========================================================================
3. Dark left Footer
========================================================================== */



/* ==========================================================================
4. Light medium footer
========================================================================== */
footer.footer-light-medium {
    .special {
        padding-left: 5px;
        padding-right: 5px;
        background-color: #f5f5f56b;
    }

    .border-top {
        border-top: 1px solid #e3e3e3;
    }

    .footer-column {
        .column-item-2-line {
            padding-bottom: 10px;
            a {
                color: $title-grey;
                &:hover {
                    color: $primary;
                }
            }
            .description {
                color: $title-grey;
                font-size: 0.75rem;
            }
        }
    }
    .small-footer-logo {
        height: 36px;
    }

    .links {
        display: flex;
        justify-content: center;
        align-items: center;
        a {
            color: $title-grey;
            margin: 0 5px;
            &:hover {
                color: $primary;
            }
        }
    }
}