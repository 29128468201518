/*! _navbar.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Navbar related styles
Changes specific to BrewEngage website
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Resets
1. Navbar placeholder
2. Fixed solid navbar
3. Static navbar
4. Shared styles
5. Sticky fade navbar
6. Light and transparent navbar
7. Base styles
8. Hero head
9. Account avatar
10. Scrollnav 
11. Hamburger menu icon
=============================================================================
***/


/* ==========================================================================
3. Static navbar
========================================================================== */
.navbar-wrapper {
    .navbar-item {
        img {
            max-height: 3.75rem;
        }
    }
}
